import React, { useEffect, useState, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import DraftsIcon from '@mui/icons-material/Drafts';
import Box from '@mui/material/Box';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import Logo from "../../../assets/images/logo.png";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';


import {
    List,
    ListItemButton,
    Toolbar,
    Tooltip,
    IconButton,
    Avatar,
    Menu,
    MenuItem,
    Container,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';



const Header = ({ isLoggedIn, loggedCustomer, isSaved }) => {
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [fullScreen, setFullScreen] = useState(false);
    const navMenuRef = useRef();


    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (screenWidth > 900) {
            setIsMenuOpen(false);
        } else if (screenWidth < 600) {
            setFullScreen(true);
        } else if (screenWidth > 600) {
            setFullScreen(false);
        }
    }, [screenWidth]);


    useEffect(() => {
        const handleMenuClose = (e) => {
            if (!navMenuRef.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener('mousedown', handleMenuClose)

        return () => {
            document.removeEventListener('mousedown', handleMenuClose)
        }
    })

    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <>
            <AppBar
                color='secondary'
                position="sticky"
                component="nav"
                sx={{
                    minHeight: '4rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                <Container maxWidth='xl' sx={{ position: 'relative' }}>

                    <Toolbar sx={{ py: 1, paddingX: '0px !important' }}>
                        <Box
                            sx={{ width: '14rem' }}
                        >
                            <img
                                src={Logo}
                                width={'100%'}
                                maxWidth='100%'
                                onClick={() => { navigate("/") }}
                                style={{ cursor: 'pointer' }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: 'end', width: "100%" }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <DraftsIcon color='primary'
                                    sx={{ fontSize: { xs: '2rem', sm: '2.4rem' } }}
                                />
                                <Box ml={"5px"} sx={{ display: { xs: 'none', md: 'block' } }}>
                                    <Typography color='primary'>
                                        Online Support
                                    </Typography>
                                    <Typography>
                                        info@jadecaps.com
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '20px'
                            }}>
                                <PhoneCallbackIcon color='primary'
                                    sx={{ fontSize: { xs: '2rem', sm: '2.4rem' } }} />
                                <Box ml={"5px"} sx={{ display: { xs: 'none', md: 'block' } }}>
                                    <Typography color='primary'>
                                        Free Contact
                                    </Typography>
                                    <Typography>
                                        +91 80 41145233
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: { xs: 'flex', md: 'none' },
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: 1
                            }}>
                                <MenuIcon color='primary'
                                    sx={{
                                        fontSize: { xs: '2rem', sm: '2.4rem' },
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleMenuClick()}
                                />
                            </Box>
                        </Box>
                    </Toolbar>

                    <Box
                        id="navWrapper"
                        ref={navMenuRef}
                        sx={{
                            position: 'absolute',
                            top: '0px',
                            right: isMenuOpen ? 0 : '-6rem',
                            backgroundColor: 'primary.main',
                            minHeight: isMenuOpen ? '100vh' : '0px',
                            width: isMenuOpen ? (fullScreen ? '100vw' : '40vw') : '0px',
                            padding: fullScreen ? '2rem' : '1rem',
                            transition: 'minHeight 0.3s ease, width 0.3s ease',
                            overflow: 'hidden',
                            boxShadow: '-2px 2px 10px rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        <List
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>

                            <CloseIcon
                                onClick={() => handleMenuClick()}
                                sx={{
                                    position: 'absolute',
                                    top: '-1.2rem',
                                    right: '-1.2rem',
                                    cursor: 'pointer',
                                    fontSize: '2rem'
                                }}
                            />

                            <ListItemButton onClick={() => navigate('/about-us')}>ABOUT US</ListItemButton>
                            <ListItemButton onClick={() => navigate('/extended-stays')}>EXTENDED STAYS</ListItemButton>
                            <ListItemButton onClick={() => navigate('/partner-with-us')}>PARTNER WITH US</ListItemButton>
                            <ListItemButton onClick={() => navigate('https://owner.jadecaps.com')}>OWNER'S PORTAL</ListItemButton>
                            <ListItemButton onClick={() => navigate('/properties')}>OUR PROPERTIES</ListItemButton>
                            <ListItemButton>BLOG</ListItemButton>
                            {  isLoggedIn  ? (
                                <Box sx={{ flexGrow: 0, mr: 2, mb: 1 }}>
                                    <Tooltip title="Open settings">
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt={loggedCustomer?.firstName} />
                                        </IconButton>&nbsp;{loggedCustomer?.firstName}
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >

                                        <MenuItem onClick={() => navigate("/profile")}>
                                            <Typography textAlign="center">My Profile</Typography>
                                        </MenuItem>

                                        <MenuItem onClick={() => navigate("/my-booking")}>
                                            <Typography textAlign="center">My Bookings</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            sessionStorage.removeItem("loggedCustomer");
                                            sessionStorage.removeItem("customerToken");
                                            window?.location?.reload();
                                        }}>
                                            <Typography textAlign="center">Logout</Typography>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            ) : (
                                <ListItemButton onClick={() => { navigate("/login") }}>LOGIN</ListItemButton>
                            )}
                            <Link to={'/list-your-property'} style={{ textDecoration: 'none' }}>
                                <ListItemButton
                                    sx={{
                                        backgroundColor: 'White',
                                        color: 'primary.main',
                                        '&:hover': { color: 'white' },
                                        maxWidth: '16rem'
                                    }}>
                                    <Typography>+ LIST YOUR PROPERTY</Typography>
                                </ListItemButton>
                            </Link>
                        </List>
                    </Box>

                </Container>
                <Box sx={{ backgroundColor: 'primary.main', display: { xs: 'none', md: 'flex' }, width: 1 }}>
                    <Container maxWidth='xl'>
                        <List
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',

                                flexWrap: 'wrap', gap: 1
                            }}>
                            <ListItemButton onClick={() => navigate('/about-us')}>ABOUT US</ListItemButton>
                            <ListItemButton onClick={() => navigate('/extended-stays')}>EXTENDED STAYS</ListItemButton>
                            <ListItemButton onClick={() => navigate('/partner-with-us')}>PARTNER WITH US</ListItemButton>
                            <ListItemButton onClick={() => window.location.href = 'https://owner.jadecaps.com'}>OWNER'S PORTAL</ListItemButton>
                            <ListItemButton onClick={() => navigate('/properties')}>OUR PROPERTIES</ListItemButton>
                            <ListItemButton>BLOG</ListItemButton>
                            { isLoggedIn   ? (
                                <Box sx={{ flexGrow: 0, mr: 2 }}>
                                    <Tooltip title="Open settings">
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt={loggedCustomer?.firstName} />
                                        </IconButton>&nbsp;{loggedCustomer?.firstName}
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        <MenuItem onClick={() => navigate("/profile")}>
                                            <Typography textAlign="center">My Profile</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => navigate("/my-booking")}>
                                            <Typography textAlign="center">My Bookings</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            sessionStorage.removeItem("loggedCustomer");
                                            sessionStorage.removeItem("customerToken");
                                            window?.location?.reload();
                                        }}>
                                            <Typography textAlign="center">Logout</Typography>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            ) : (
                                <ListItemButton onClick={() => { navigate("/login") }}>LOGIN</ListItemButton>
                            )}
                            <Link to={'/list-your-property'} style={{ textDecoration: 'none' }}>

                                <ListItemButton
                                    sx={{
                                        backgroundColor: 'White',
                                        color: 'primary.main',
                                        '&:hover': { color: 'white' },
                                        maxWidth: '16rem'
                                    }}><Typography>+ LIST YOUR PROPERTY</Typography>
                                </ListItemButton>
                            </Link>
                        </List>
                    </Container>
                </Box>
            </ AppBar>

        </>
    )
}

export default Header
